.timeline
	display block



	&__inner
		display block
		overflow hidden



	&__list
		counter-reset timeline-list
		position relative
		display block
		max-width 480px
		margin 0 auto
		padding 0
		list-style none

		&:before
			content ''
			position absolute
			top 0
			left 16px
			display block
			width 3px
			height 100%
			background c-yel

		+above(breakpoints.m)
			display flex
			max-width initial
			margin-right -15px
			margin-left -15px
			justify-content center

			&:before
				top 16px
				left 15px
				width 75%
				height 3px



	&__item
		counter-increment timeline-list
		position relative
		display block
		margin 0 auto
		padding 3px 0 0 50px
		list-style none

		&:last-child
			margin-bottom 0
			background c-odd // or c-white if section not odd?

		&:before
			content counter(timeline-list)
			position absolute
			top 0
			left 0
			display flex
			justify-content center
			align-items center
			flex-direction column
			width 35px
			height 35px
			color c-def
			font-size 17px
			font-weight 700
			background c-orange
			border-radius 50%

		+above(breakpoints.m)
			flex 1 0 25%
			min-width 187px
			max-width 320px
			padding-top 50px
			padding-right 15px
			padding-left 15px

			&:last-child
				background transparent // or c-white if section not odd?

			&:before
				left 15px

		+below(breakpoints.m - 1)
			margin-bottom 35px