.contacts
	display block


	&__element
		display block
		margin 0 0 30px

		&:last-child
			margin-bottom 0

		// Modifiers
		&_type
			&_single-line
				.contacts
					&__element-title
					&__element-body
						display inline-block