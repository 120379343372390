.gallery
	display block



	&__wrapper
		display block
		padding 0 15px



	&__list
		display flex
		justify-content center
		flex-wrap wrap
		margin 0 -15px
		padding 0
		list-style none

		+above(breakpoints.m)
			flex-wrap nowrap



	&__item
		display block
		flex 1
		min-width 50%
		max-width 768px
		margin 0
		padding 0
		list-style none

		+above(breakpoints.m)
			min-width 0

			def-trn flex

			&:first-child
				.gallery
					&__photo
						clip-path polygon(0 0, 100% 0, calc(100% - 80px) 100%, 0 100%)
						margin-left 0

			&:last-child
				.gallery
					&__photo
						clip-path polygon(80px 0, 100% 0, 100% 100%, 0 100%)
						margin-right 0

			&:hover
				flex-grow 1.5



	&__photo
		position relative
		display block

		&[href]
			cursor pointer

		&:before
			content ''
			display block
			padding 0 0 100%

		+above(breakpoints.m)
			clip-path polygon(80px 0, 100% 0, calc(100% - 80px) 100%, 0 100%)
			margin 0 -35px

			&:before
				padding-bottom 340px