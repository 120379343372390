.well
	display block



	&__inner
		position relative
		display flex
		overflow hidden
		flex-direction column
		justify-content center
		padding 115px 0



	&__illustration
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		z-index 1
		display block

		&:before
			content ''
			position absolute
			top 0
			right 0
			left 0
			bottom 0
			z-index 2
			display block
			background rgba(0, 0, 0, .3)



	&__holder
		position relative
		z-index 2
		display block



	&__pretitle
		position relative
		display block
		max-width 575px
		margin 0 0 10px
		padding 0 0 0 30px
		color c-yel
		font-size 20px

		&:last-child
			margin-bottom 0

		&:before
			content ''
			position absolute
			top 50%
			left 0
			display block
			width 20px
			height 1px
			transform translate(0, -50%)
			background c-yel



	&__title
		display block
		max-width 575px
		margin 0 0 20px
		color c-white
		font-size 37px
		font-weight 700

		&:last-child
			margin-bottom 0

		+above(breakpoints.m)
			font-size 47px



	&__body
		display block
		max-width 575px
		margin 0 0 20px
		color c-white
		font-size 20px
		font-weight 300

		&:last-child
			margin-bottom 0



	&__controls
		display block
		max-width 575px
		margin 0 0 20px

		&:last-child
			margin-bottom 0



	&__control
		display block
		max-width 266px



	// Modifier
	&_size
		&_fullscreen
			min-height 100vh

			.well
				&__inner
					min-height 100vh