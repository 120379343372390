.menu
	display block

	&__list
		display block
		margin 0
		padding 0
		list-style none



	&__item
		display block
		margin 0 0 10px
		padding 0

		&:last-child
			margin-bottom 0



		// Modifiers
		&_state
			&_active
				.menu
					&__link
						color c-orange

			&_disable
				.menu
					&__link
						color c-lightb
						pointer-events none

			&_hide
				display none



	&__link
		display block
		margin 0
		padding 0
		color c-def
		font-size 17px
		font-weight 400
		text-decoration none

		def-trn color

		&:hover
		&:focus
			color c-orange


	// Modifiers
	&_type
		&_inline
			.menu
				padding 0 15px

				&__list
					display flex
					flex-wrap wrap
					margin 0 -15px

				&__item
					margin 0 auto
					padding 3px 15px

	&_color
		&_white
			.menu
				&__link
					color c-white

					&:hover
					&:focus
						color c-orange
