.illustration
	position absolute
	top 0
	right 0
	left 0
	bottom 0
	display block



	&__image
		position absolute
		top 50%
		left 50%
		transform translate(-50%, -50%)
		display block
		width auto
		max-width 100%
		height auto
		max-height 100%
		margin 0 auto

		// Modisfiers
		&_type
			&_svg
				display block
				width 100%



	// Modifiers
	&_type
		&_bg
			overflow hidden

			.illustration
				&__image
					top 0
					right 0
					left 0
					bottom 0
					transform translate(0, 0)
					width 100%
					max-width none
					height 100%
					max-height none
					object-fit cover

		&_circle
			overflow hidden
			border-radius 50%
