.button
	display block
	min-width 266px
	padding 13px 35px
	color c-def
	font-size 17px
	line-height 24px
	text-decoration none
	background c-orange
	border 2px solid c-orange
	border-radius 27px
	outline none

	def-trn color, background-color, border-color

	&:hover
	&:focus
		color c-orange
		background-color c-trn
		border-color c-orange



	&__inner
		display block
		text-align center



	// Modifiers
	&_type
		&_inline
			display inline-block

	&_style
		&_white
			color c-white
			background c-trn
			border-color c-white

			&:hover
			&:focus
				color c-def
				background-color c-white
				border-color c-white

		&_mob-bars
			position relative
			width 57px
			min-width 0
			height 57px
			font-size 0
			background c-trn
			border-color c-white

			&:before
			&:after
				content ''
				position absolute
				left 50%
				transform translate(-50%, 0)
				display block
				width 30px
				height 2px
				background c-white
				border-radius 4px

			&:before
				top 15px

			&:after
				bottom 15px

			&:hover
			&:focus
				background-color c-white
				border-color c-white

				&:before
				&:after
					background-color c-def

				.button
					&__inner
						background-color c-def

			.button
				&__inner
					position absolute
					top 50%
					left 50%
					transform translate(-50%, -50%)
					display block
					width 30px
					height 2px
					background c-white
					border-radius 4px
