.program-snippet
	display block



	&__wrapper
		position relative
		display block
		width 100%
		// max-width 290px
		margin 0 auto
		padding 0 0 0 50px

		&:before
			content counter(program-list)
			position absolute
			top 0
			left 0
			display flex
			justify-content center
			align-items center
			width 35px
			height 35px
			color c-def
			font-size 17px
			font-weight 700
			background c-orange
			border-radius 50%



	&__title
		display block
		// min-height 54px
		margin 0 0 10px
		color c-def
		font-size 22px
		font-weight 800

		&:last-child
			margin-bottom 0



	&__body
		display block
		margin 0 0 10px
		color c-def
		font-size 16px

		&:last-child
			margin-bottom 0



	&__italic
		display block
		font-style italic