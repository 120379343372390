@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Black.eot')
	src local('Mont Black'), local('Mont-Black'),
		url('../fonts/Mont/Mont-Black.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Black.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Black.woff') format('woff'),
		url('../fonts/Mont/Mont-Black.ttf') format('truetype')
	font-weight 900
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-ThinItalic.eot')
	src local('Mont Thin Italic'), local('Mont-ThinItalic'),
		url('../fonts/Mont/Mont-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-ThinItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-ThinItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-ThinItalic.ttf') format('truetype')
	font-weight 100
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-ExtraLight.eot')
	src local('Mont ExtraLight'), local('Mont-ExtraLight'),
		url('../fonts/Mont/Mont-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-ExtraLight.woff2') format('woff2'),
		url('../fonts/Mont/Mont-ExtraLight.woff') format('woff'),
		url('../fonts/Mont/Mont-ExtraLight.ttf') format('truetype')
	font-weight 200
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Bold.eot')
	src local('Mont Bold'), local('Mont-Bold'),
		url('../fonts/Mont/Mont-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Bold.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Bold.woff') format('woff'),
		url('../fonts/Mont/Mont-Bold.ttf') format('truetype')
	font-weight bold
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-HeavyItalic.eot')
	src local('Mont Heavy Italic'), local('Mont-HeavyItalic'),
		url('../fonts/Mont/Mont-HeavyItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-HeavyItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-HeavyItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-HeavyItalic.ttf') format('truetype')
	font-weight 800
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-BlackItalic.eot')
	src local('Mont Black Italic'), local('Mont-BlackItalic'),
		url('../fonts/Mont/Mont-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-BlackItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-BlackItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-BlackItalic.ttf') format('truetype')
	font-weight 900
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-ExtraLightItalic.eot')
	src local('Mont ExtraLight Italic'), local('Mont-ExtraLightItalic'),
		url('../fonts/Mont/Mont-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-ExtraLightItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-ExtraLightItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-ExtraLightItalic.ttf') format('truetype')
	font-weight 200
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Heavy.eot')
	src local('Mont Heavy'), local('Mont-Heavy'),
		url('../fonts/Mont/Mont-Heavy.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Heavy.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Heavy.woff') format('woff'),
		url('../fonts/Mont/Mont-Heavy.ttf') format('truetype')
	font-weight 800
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-SemiBold.eot')
	src local('Mont SemiBold'), local('Mont-SemiBold'),
		url('../fonts/Mont/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-SemiBold.woff2') format('woff2'),
		url('../fonts/Mont/Mont-SemiBold.woff') format('woff'),
		url('../fonts/Mont/Mont-SemiBold.ttf') format('truetype')
	font-weight 600
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-RegularItalic.eot')
	src local('Mont Regular Italic'), local('Mont-RegularItalic'),
		url('../fonts/Mont/Mont-RegularItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-RegularItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-RegularItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-RegularItalic.ttf') format('truetype')
	font-weight normal
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-BoldItalic.eot')
	src local('Mont Bold Italic'), local('Mont-BoldItalic'),
		url('../fonts/Mont/Mont-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-BoldItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-BoldItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-BoldItalic.ttf') format('truetype')
	font-weight bold
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-SemiBoldItalic.eot')
	src local('Mont SemiBold Italic'), local('Mont-SemiBoldItalic'),
		url('../fonts/Mont/Mont-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-SemiBoldItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-SemiBoldItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-SemiBoldItalic.ttf') format('truetype')
	font-weight 600
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-LightItalic.eot')
	src local('Mont Light Italic'), local('Mont-LightItalic'),
		url('../fonts/Mont/Mont-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-LightItalic.woff2') format('woff2'),
		url('../fonts/Mont/Mont-LightItalic.woff') format('woff'),
		url('../fonts/Mont/Mont-LightItalic.ttf') format('truetype')
	font-weight 300
	font-style italic

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Thin.eot')
	src local('Mont Thin'), local('Mont-Thin'),
		url('../fonts/Mont/Mont-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Thin.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Thin.woff') format('woff'),
		url('../fonts/Mont/Mont-Thin.ttf') format('truetype')
	font-weight 100
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Regular.eot')
	src local('Mont Regular'), local('Mont-Regular'),
		url('../fonts/Mont/Mont-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Regular.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Regular.woff') format('woff'),
		url('../fonts/Mont/Mont-Regular.ttf') format('truetype')
	font-weight normal
	font-style normal

@font-face
	font-family 'Mont'
	src url('../fonts/Mont/Mont-Light.eot')
	src local('Mont Light'), local('Mont-Light'),
		url('../fonts/Mont/Mont-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Mont/Mont-Light.woff2') format('woff2'),
		url('../fonts/Mont/Mont-Light.woff') format('woff'),
		url('../fonts/Mont/Mont-Light.ttf') format('truetype')
	font-weight 300
	font-style normal
