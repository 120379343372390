.title
	display block
	margin 0 0 40px auto
	font-family 'Mont'
	font-size 26px
	font-weight 600



	// Modifiers
	&_size
		&_h1
			font-size 32px

			+above(breakpoints.m)
				font-size 42px

		&_h2
			font-size 30px

			+above(breakpoints.m)
				font-size 40px

		&_h3
			font-size 28px

			+above(breakpoints.m)
				font-size 38px

		&_h4
			font-size 20px

			+above(breakpoints.m)
				font-size 22px

		&_h5
			font-size 18px

			+above(breakpoints.m)
				font-size 20px

		&_h6
			font-size 17px

			+above(breakpoints.m)
				font-size 18px

	&_weight
		&_thin
			font-weight 100

		&_extra-light
			font-weight 200

		&_light
			font-weight 300

		&_regular
			font-weight 400

		&_semi-bold
			font-weight 600

		&_bold
			font-weight 700

		&_heavy
			font-weight 800

		&_black
			font-weight 900

	&_align
		&_center
			text-align center

		&_right
			text-align right

		&_left
			text-align left

	&_case
		&_up
			text-transform uppercase

	&_gap
		&_no
			margin-bottom 0

		&_thin
			margin-bottom 10px

		&_small
			margin-bottom 20px

		&_medium
			margin-bottom 30px

		&_def
			margin-bottom 40px

		&_big
			margin-bottom 50px

		&_huge
			margin-bottom 60px

