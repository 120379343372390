.carousel
	display block



	&__wrapper
		position relative
		display block



	&__holder
		display block
		width auto



	&__body
		position relative
		z-index 1
		display flex



	&__navigation
		position absolute
		top 50%
		right 0
		left 0
		z-index 2
		display flex
		justify-content space-between
		align-items center
		width auto
		transform translate(0, -50%)
		pointer-events none



	&__navigation-button
		position relative
		display block
		flex 0 0 auto
		width 30px
		fill c-yel
		cursor pointer
		pointer-events auto
		outline none

		def-trn fill

		&:before
			content ''
			display block
			padding 0 0 100%

		&:hover
		&:focus
			fill c-orange