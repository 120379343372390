.module
	display block



	&__wrapper
		display block

		// Modifiers
		&_style
			&_rounded
				padding 10px 25px 30px
				border 2px solid c-def-button
				border-radius 8px

			&_bordered
				padding 30px 20px
				background c-white
				border 5px solid c-yel

		&_align
			&_left
				margin-right auto
				margin-left 0

			&_center
				margin-right auto
				margin-left auto

			&_right
				margin-right 0
				margin-left auto

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-left
						+above(breakpoint)
							margin-right auto
							margin-left 0

					&-center
						+above(breakpoint)
							margin-right auto
							margin-left auto

					&-right
						+above(breakpoint)
							margin-right 0
							margin-left auto

		&_text
			&_left
				text-align left

			&_center
				text-align center

			&_right
				text-align right

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-left
						+above(breakpoint)
							text-align left

					&-center
						+above(breakpoint)
							text-align center

					&-right
						+above(breakpoint)
							text-align right



	// Modifiers
	&_full
		&_height
			display flex
			flex-direction column
			min-height 100%

			.module
				&__holder
					flex 1 0 100%