.logo
	display block



	&__link
		display flex
		align-items center
		color c-def
		text-decoration none

		def-trn color

		&:hover
		&:focus
			color c-red



	&__illustration
		position relative
		display block
		width 83px

		&:before
			content ''
			display block
			width 100%
			padding 0 0 48.19277108433735%



	&__title
		// display none
		display inline-flex
		flex-direction column
		// min-width 220px
		height 100%
		padding 0 0 0 23px
		color c-white
		font-size 10px
		// font-size 0
		font-weight 600
		text-transform uppercase
		justify-content center

		// Modofiers
		&_size
			&_big
				min-width 220px
				font-size 14px

			&_zero
				min-width 0
				font-size 0

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-big
						+above(breakpoint)
							min-width 220px
							font-size 14px

					&-zero
						+above(breakpoint)
							min-width 0
							font-size 0




	&__title-line
		display block
