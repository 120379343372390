.speakers
	display block
	overflow hidden



	&__list
		display flex
		flex-wrap wrap
		justify-content center
		margin 0 -15px
		padding 0
		list-style none



	&__item
		display block
		flex 1 0 auto
		min-width 320px
		max-width 100%
		margin 0 auto 35px
		padding 0 15px

		&:last-child
			margin-bottom 0