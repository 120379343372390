.mobile-nav
	display block
	overflow  auto



	&__wrapper
		display block
		padding 10px



	&__controller
		position relative
		display block
		width 20px
		height 20px
		color transparent
		font-size 0
		background transparent
		border none
		outline none

		&:before
		&:after
			content ''
			position absolute
			top 50%
			left 50%
			display block
			width 100%
			height 3px
			background c-def
			border-radius 50%

			def-trn background

		&:before
			transform translate(-50%, -50%) rotate(45deg)

		&:after
			transform translate(-50%, -50%) rotate(-45deg)

		&:hover,
		&:focus
			&:before,
			&:after
				background c-red


		// Modifiers
		&_align
			&_left
				margin-right auto
				margin-left 0

			&_center
				margin-right auto
				margin-left auto

			&_right
				margin-right 0
				margin-left auto

	&__request
		display flex

		// Modifiers
		&_align
			&_center
				justify-content center
