.speakers-snippet
	display



	&__wrapper
		display block
		max-width 220px
		margin 0 auto



	&__canvas
		display block
		width 220px
		margin 0 auto 25px
		padding 5px
		background c-orange
		border-radius 50%

		&:last-child
			margin-bottom 0



	&__illustration
		position relative
		display block

		&:before
			content ''
			display block
			padding 0 0 100%



	&__title
		display block
		margin 0 auto 20px
		color c-def
		font-size 26px
		font-weight 600
		text-align center

		&:last-child
			margin-bottom 0



	&__description
		display block
		margin 0 auto 20px
		color c-def
		font-size 17px
		text-align center

		&:last-child
			margin-bottom 0