@import 'helpers/variables'
@import 'helpers/mixins'

colsSize(cols, gutter, breakpoints)
	min-height 1px
	padding 0 gutter

	// Modifiers
	&_gap
		&_b
			margin-bottom grids.default.gutter * 2

		for name, breakpoint in grids.default.breakpoints
			&_{name}
				&-b
					+above(breakpoint)
						margin-bottom grids.default.gutter * 2

		&_no
			margin-bottom 0

		for name, breakpoint in grids.default.breakpoints
			&_{name}
				&-no
					+above(breakpoint)
						margin-bottom 0

	&_see
		&_no
			display none

		&_yes
			display block

		for name, breakpoint in grids.default.breakpoints
			&_{name}
				&-no
					+above(breakpoint)
						display none

				&-yes
					+above(breakpoint)
						display block


	&_align-self
		&_start
			align-items flex-start

		&_center
			align-items center

		&_end
			align-items flex-end

		for name, breakpoint in grids.default.breakpoints
			&_{name}
				&-start
					+above(breakpoint)
						align-items flex-start

				&-center
					+above(breakpoint)
						align-items center

				&-end
					+above(breakpoint)
						align-items flex-end

	&_size
		&_auto
			flex-grow 1

		&_min
			flex-grow 0

		for name, breakpoint in breakpoints
			&_{name}
				&-auto
					+above(breakpoint)
						flex-grow 1

				&-min
					+above(breakpoint)
						flex-grow 0

		for num in (1..cols)
			&_{num}
				flex 0 0 ((100 / cols) * num)%
				max-width ((100 / cols) * num)%

		for name, breakpoint in breakpoints
			&_{name}
				for num in (1..cols)
					&-{num}
						+above(breakpoint)
							flex 0 0 ((100 / cols) * num)%
							max-width ((100 / cols) * num)%

	&_order
		for num in (1..cols)
			&_{num}
				order num

		&_first
			order -1

		&_last
			order (cols + 1)

		for name, breakpoint in breakpoints
			&_{name}
				for num in (1..cols)
					&-{num}
						+above(breakpoint)
							order num

				&-first
					+above(breakpoint)
						order -1

				&-last
					+above(breakpoint)
						order (cols + 1)

	&_push
		for num in (1..cols)
			&_{num}
				position relative
				left ((100 / cols) * num)%

		for name, breakpoint in breakpoints
			&_{name}
				for num in (1..cols)
					&-{num}
						+above(breakpoint)
							position relative
							left ((100 / cols) * num)%

	&_pull
		for num in (1..cols)
			&_{num}
				position relative
				right ((100 / cols) * num)%

		for name, breakpoint in breakpoints
			&_{name}
				for num in (1..cols)
					&-{num}
						+above(breakpoint)
							position relative
							right ((100 / cols) * num)%

	&_offset
		for num in (1..cols)
			&_{num}
				margin-left ((100 / cols) * num)%

		for name, breakpoint in breakpoints
			&_{name}
				for num in (1..cols)
					&-{num}
						+above(breakpoint)
							margin-left ((100 / cols) * num)%

.grid
	display block
	box-sizing border-box
	min-width 320px



	&__container
		box-sizing border-box
		width 100%
		max-width: grids.default.containerMaxWidth
		margin 0 auto
		padding: 0 grids.default.gutter

		// Modifiers
		&_width

			&_full
				width 100%
				max-width 100%

			&_fixed
				for name, breakpoint in breakpoints
					+above(breakpoint)
						if breakpoint > 420
							width 100%
							max-width (breakpoint - 100)
						else
							width 320px

			for name, breakpoint in breakpoints
				&_{name}
					&-tiny
						+above(breakpoint)
							padding 0 180px



	&__group
		display flex
		flex-wrap wrap
		box-sizing border-box
		margin: 0 (-(grids.default.gutter))

		// Modifiers
		&_no
			&_gutters
				.grid
					&__col
						padding 0

		&_align-items
			&_start
				align-items flex-start

			&_center
				align-items center

			&_end
				align-items flex-end

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-start
						+above(breakpoint)
							align-items flex-start

					&-center
						+above(breakpoint)
							align-items center

					&-end
						+above(breakpoint)
							align-items flex-end

		&_justify-content
			&_start
				justify-content flex-start

			&_center
				justify-content center

			&_end
				justify-content flex-end

			&_around
				justify-content space-around

			&_between
				justify-content space-between

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-start
						+above(breakpoint)
							justify-content flex-start

					&-center
						+above(breakpoint)
							justify-content center

					&-end
						+above(breakpoint)
							justify-content flex-end

					&-around
						+above(breakpoint)
							justify-content space-around

					&-between
						+above(breakpoint)
							justify-content space-between

	&__col
		position relative
		flex-grow 1
		flex-basis 0
		box-sizing border-box

	.grid
		&__group
			& > .grid__col
				colsSize(grids.default.cols, grids.default.gutter, grids.default.breakpoints)



	&__holder
		box-sizing border-box
		height 100%



	&__panel
		display block
		box-sizing border-box
		padding: grids.default.gutter



	&__breaker
		display block
		box-sizing border-box
		width 100% !important // @stylint ignore

		// Modifiers
		for name, breakpoint in grids.default.breakpoints
			&_{name}
				display none

				+above(breakpoint)
					display block



	&__view-panel
		box-sizing border-box
		padding 30px 0

		// &:first-child
		// 	padding-top 0

		// &:last-child
		// 	padding-bottom 0



	// Modifiers
	for name, gridFields in grids
		&_cols
				&_{gridFields.cols}
					.grid
						&__group
							& > .grid__col
								colsSize(gridFields.cols, gridFields.gutter, gridFields.breakpoints)

						&__group
							&_no_gutters
								.grid
									&__col
										padding 0

		&_style
			&_debug
				.grid
					/*&__container
						outline 1px solid orange*/

					&__col
						outline 1px solid red

					&__panel
						background #f4f4f4

					&__holder
						outline 1px solid green

			&_demo
				.grid
					&__col
						padding-top: (gridFields.gutter / 2)
						padding-bottom: (gridFields.gutter / 2)

					&__panel
						color #7d7d7d
						font-size 14px
						line-height 1.2
						background #f4f4f4
						border 1px solid #c7c7c7
