.modal
	position fixed
	top 0
	right 0
	left 0
	bottom 0
	z-index 10
	display none



	&__overlay
		position absolute
		top 0
		left 0
		z-index 1
		display block
		width 100%
		height 100%
		font-size 0
		cursor pointer
		background rgba(0, 0, 0, .62)



	&__inner
		display flex
		justify-content center
		align-items center
		height 100%
		padding 10px



	&__holder
		position relative
		z-index 2
		display flex
		flex-direction column
		width 100%
		max-width 100%
		max-height 100%
		background c-white

		// Modifiers
		&_size
			&_thin
				max-width 520px



	&__header
		display block
		flex 0 0 auto
		padding 20px 40px 10px 20px



	&__body
		display block
		overflow-y auto
		flex 0 1 auto
		padding 10px 20px 20px



	&__footer
		display block
		flex 0 0 auto
		padding 10px


	&__controller
		display block

		// Modifiers
		&_type
			&_close
				position absolute
				top 10px
				right 10px
				width 20px
				height 20px
				color transparent
				font-size 0
				background transparent
				border none
				outline none

				&:before
				&:after
					content ''
					position absolute
					top 50%
					left 50%
					display block
					width 100%
					height 3px
					background c-def
					border-radius 50%

					def-trn background

				&:before
					transform translate(-50%, -50%) rotate(45deg)

				&:after
					transform translate(-50%, -50%) rotate(-45deg)

				&:hover,
				&:focus
					&:before,
					&:after
						background c-red




	// Modifiers
	&_state
		&_open
			display block

	&_size
		&_full
			.modal
				&__holder
					height 100%

				&__body
					height 100%