.section
	display block
	padding 115px 0



	&__header
		display block



	&__body
		display block
		margin 0 0 40px

		&:last-child
			margin-bottom 0

		// Modifiers
		&_size
			&_min
				width auto
				max-width 600px

		&_align
			&_center
				margin 0 auto



	&__footer
		display block



	// Modifiers
	&_visible
		&_yes
			display block
		&_no
			display none

		for name, breakpoint in grids.default.breakpoints
			&_{name}
				&-yes
					+above(breakpoint)
						display block

				&-no
					+above(breakpoint)
						display none

	&_bg
		&_odd
			background #f4f4f4

		&_even
			background #fff

	&_p
		&_0
			padding-top 0
			padding-bottom 0

		&_thin
			padding-top 20px
			padding-bottom 20px

		&_small
			padding-top 55px
			padding-bottom 55px

	&_pt
		&_0
			padding-top 0

		&_small
			padding-top 55px

	&_pb
		&_0
			padding-bottom 0

		&_small
			padding-bottom 55px