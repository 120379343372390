.nav
	display block



	&__list
		display flex
		flex-wrap wrap
		margin 0
		padding 0
		list-style none

		&_align
			&_center
				justify-content center

				.nav
					&__link
						text-align center

			&_saround
				justify-content space-around

				.nav
					&__link
						text-align center



	&__item
		display flex
		flex-direction column
		justify-content center
		margin 0 5px
		padding 5px 8px

		// Modifiers
		&_state
			&_current
				.nav
					&__link
						border-bottom-color c-def

						&:hover
							color c-def


	&__link
		display block
		margin 0
		color inherit
		font-family 'Mont'
		font-size 14px
		font-weight 600
		// white-space nowrap
		text-decoration none
		background c-white
		border none
		border-bottom 2px solid transparent

		def-trn color border-color

		&:hover
			color c-red