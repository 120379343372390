.text-snippet
	display block



	&__inner
		display block
		max-width 355px
		margin 0 auto

		// Modifiers
		&_size
			&_full
				max-width 100%



	&__header
		display block
		margin 0 auto 20px

		&:last-child
			margin-bottom 0



	&__body
		display block
		margin 0 auto 20px

		&:last-child
			margin-bottom 0



	&__canvas
		display block
		width 150px
		margin 0 auto 25px
		padding 33px
		background c-orange
		border-radius 50%

		&:last-child
			margin-bottom 0



	&__icon
		display block
		// width 80px



	&__illustration
		position relative
		display block

		&:before
			content ''
			display block
			padding 0 0 100%



	&__title
		display block
		margin 0 auto 20px
		color c-def
		font-size 30px
		font-weight 600
		text-align center

		&:last-child
			margin-bottom 0

		// Modifiers
		&_align
			&_left
				text-align left

		&_size
			&_small
				font-size 18px

			&_medium
				font-size 25px

			&_def
				font-size 30px

			for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-small
						+above(breakpoint)
							font-size 18px

					&-medium
						+above(breakpoint)
							font-size 25px

					&-def
						+above(breakpoint)
							font-size 30px

		&_case
			&_up
				text-transform uppercase

		&_weight
			&_thin
				font-weight 100

			&_extra-light
				font-weight 200

			&_light
				font-weight 300

			&_regular
				font-weight 400

			&_semi-bold
				font-weight 600

			&_bold
				font-weight 700

			&_heavy
				font-weight 800

			&_black
				font-weight 900



	&__description
		display block
		margin 0 auto 20px
		color c-def
		font-size 17px
		text-align center

		&:last-child
			margin-bottom 0

		// Modifiers
		&_align
			&_left
				text-align left

		&_size
			&_small
				font-size 14px

			&_medium
				font-size 16px
