.footer
	display block



	&__copyright
		display block
		padding 30px 0 0



	&__module
		display block
		margin 0 0 30px