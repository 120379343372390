.social-icons
	display block
	overflow hidden



	&__list
		display flex
		margin 0 -10px
		padding 0
		list-style none



	&__item
		display block
		flex 0 0 auto
		margin 0
		padding 0 10px



	&__link
		display block
		border-radius 50%
		opacity 1

		def-trn opacity

		&:hover
		&:focus
			opacity .5



	&__illustration
		position relative
		display block
		width 40px

		&:before
			content ''
			display block
			padding 0 0 100%



	// Midifiers
	&_align
		&_left
			.social-icons
				&__list
					justify-content flex-start

		&_center
			.social-icons
				&__list
					justify-content center

		&_right
			.social-icons
				&__list
					justify-content flex-end

		for name, breakpoint in grids.default.breakpoints
				&_{name}
					&-left
						+above(breakpoint)
							.social-icons
								&__list
									justify-content flex-start

					&-center
						+above(breakpoint)
							.social-icons
								&__list
									justify-content center

					&-right
						+above(breakpoint)
							.social-icons
								&__list
									justify-content flex-end