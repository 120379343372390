.page
	position relative
	display flex
	flex-direction column
	min-height 100%



	&__body
		display flex
		flex 1 0 auto
		flex-direction column



	&__header
		position fixed
		top 0
		right 0
		left 0
		z-index 9
		display block



	&__content
		display block
		flex 1 0 auto



	&__footer
		display block
		flex 0 0 auto
		color c-def



	&__overlay
		position absolute
		top 0
		left 0
		z-index 20
		display none
		width 100%
		height 100%
		font-size 0
		cursor pointer
		background rgba(0, 0, 0, .62)


	&__mobile-nav
		position fixed
		top 0
		left 0
		z-index 21
		display block
		overflow hidden auto
		width 100%
		min-width 320px
		max-width 360px
		min-height 100vh
		max-height 100vh
		background c-white
		transform translate(-100%, 0)

		def-trn transform




	// Modifiers
	&_state
		&_modal-opened
			position relative
			overflow hidden

		&_nav-opened
			position relative
			overflow hidden

			.page
				&__overlay
					display block

				&__mobile-nav
					transform translate(0, 0)
