// Clear the float on any element.
clearfix()
	&:after
		content ''
		display table
		clear both

// Fix no working `-webkit-text-size-adjust: 100%;` in Android.
fix-text-size-adjust()
	background-image linear-gradient(transparent, transparent)

// Set element size
size($width, $height = $width)
	width $width
	height $height

// Set margin on left & right
margin-x($margin)
	margin-right $margin
	margin-left $margin

// Set margin on top & bottom
margin-y($margin)
	margin-top $margin
	margin-bottom $margin

// Set pading on left & right
padding-x($padding)
	padding-right $padding
	padding-left $padding

// Set padding on top & bottom
padding-y($padding)
	padding-top $padding
	padding-bottom $padding

// Set one static retina image
retinaBackground($image, $ext = 'png')
	background-image url(DIR_IMG + $image + '.' + $ext)

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)
		background-image url(DIR_IMG + $image + '@2x.' + $ext)

placeholder($color, $fontSize)
	&::placeholder
		color $color
		font-size $fontSize
		opacity 1

ie-only()
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
		{block}

trn-join(args, props...)
	for arg in args
		push(props, arg trn-speed trn-ease)
	unquote(join(', ', props))

def-trn()
	transition trn-join(arguments)

shadow-join(color, args, props...)
	for arg in args
		push(props, s('drop-shadow(0 0 %s %s)', arg, color))
	unquote(join(' ', props))

// drop-shadow-multiple(#f00, 12px, 13px) or drop-shadow-multiple #f00, 12px, 13px
// output: drop-shadow(0 0 12px #f00) drop-shadow(0 0 13px #f00)
drop-shadow-multiple(color, args...)
	filter shadow-join(color, args)

