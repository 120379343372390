.program
	display block
	overflow hidden



	&__list
		counter-reset program-list
		display grid
		grid-row-gap 30px
		grid-column-gap 15px
		grid-template-columns repeat(auto-fill, minmax(290px, 1fr))
		padding 0
		// display flex
		// justify-content flex-start
		// flex-wrap wrap
		// margin 0 -15px
		list-style none



	&__item
		counter-increment program-list
		position relative
		display block
		// flex 0 1 auto
		// min-width 290px
		// max-width 100%
		min-height 35px
		margin 0 0 30px
		// padding 0 15px
		list-style none