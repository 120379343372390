.tabs
	display block




	&__nav
		display block
		margin 0 0 45px



	&__content
		display block



	&__pane
		display none

		// Modifiers
		&_state
			&_current
				display block
