.news-snippet
	display block



	&__wrapper
		position relative
		display block
		margin 0 auto

		+below(breakpoints.s)
			max-width 505px



	&__illustation
		display block
		background-repeat no-repeat
		background-position 50% 50%
		background-size cover

		&:before
			content ''
			display block
			padding 0 0 100%



	&__illustation-image
		display none



	&__holder
		position absolute
		top 0
		right 0
		left 0
		bottom 0
		display flex
		flex-direction column
		justify-content space-around
		min-height 100%
		padding 50px 55px 75px
		background rgba(0, 0, 0, .62)



	&__date
		position relative
		display block
		margin 0 0 20px
		padding 0 0 20px
		color c-white
		font-size 14px
		font-weight 400
		text-align center
		text-transform capitalize

		&:last-child
			margin-bottom 0

		&:after
			content ''
			position absolute
			top 100%
			left 50%
			display block
			width 24px
			height 5px
			background url(DIR_IMG+'white-dash.png') no-repeat 50% 50%
			background-size contain
			transform translate(-50%, -100%)



	&__title
		display block
		margin 0 0 20px
		color c-white
		font-size 16px
		font-weight 900
		text-align center

		&:last-child
			margin-bottom 0


	&__title-link
		display inline
		color inherit
		font inherit
		text-decoration none

		def-trn color

		&:hover
		&:focus
		&:active
			color c-def-button



	&__controls
		text-align center