.infographic-snippet
	display block



	&__inner
		display block
		max-width 310px
		margin 0 auto



	&__illustration
		position relative
		display block
		width 114px
		margin 0 auto 20px

		&:last-child
			margin-bottom 0

		&:before
			content ''
			display block
			padding 0 0 100%



	&__title
		display block
		margin 0 auto 20px
		font-size 18px
		font-weight 600
		text-align center

		&:last-child
			margin-bottom 0



	&__title-line
		display block



	&__count
		display block
		margin 0 auto 20px
		font-size 51px
		font-weight 600
		text-align center

		&:last-child
			margin-bottom 0