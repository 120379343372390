.header
	display block



	&__inner
		display block
		padding 25px 10px
		background rgba(0, 0, 0, .55)



	&__logo
		display flex
		align-items center
		justify-content flex-start
		min-height 100%



	&__menu
		display flex
		align-items center
		justify-content center
		min-height 100%



	&__reg-button
		display flex
		align-items center
		justify-content flex-end
		min-height 100%



	&__mob-bars
		display flex
		align-items center
		justify-content flex-end
		min-height 100%