.about-snippet
	display block



	&__body
		display block
		max-width 475px
		margin 0 0 50px



	&__controllers
		display block



	&__controller
		display block
		max-width 266px



	&__illustration
		position relative
		display block

		&:before
			content ''
			display block
			padding 0 0 62.17687074829932%